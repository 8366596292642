/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../../global/common";

$diagram-width: 420px;
$diagram-caption-width: 300px;

.concept-diagram {
  width: $diagram-width;
  margin: 0;
  padding: 0;

  flex: 0 0 auto;

  &.adaptive {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  object {
    background-color: $white;
  }

  figcaption {
    font-size: 16px;
    text-align: center;
    width: $diagram-caption-width;
    margin: 10px auto 0;
  }
}
