/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

$button_color: #2d6ca2;
.wrapper {
  display:    inline-block;
  max-width:  300px;
  min-width:  250px;
  word-break: break-all;
}

.ellipsis-action-button {
  color:        $button_color;
  padding-left: 10px;
  cursor:       pointer;
}

.fix-ellipsized {
  overflow:      hidden;
  white-space:   nowrap;
  text-overflow: ellipsis;
}

