/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.callout {
  font-weight:   600;
  font-size:     12px;
  padding:       7px 40px 7px 30px;
  border:        1px solid transparent;
  border-radius: $global-border-radius;
  margin:        0 0 10px 0;
  position:      relative;

  p {
    margin: 0;
  }

  a {
    color: $link-color;
    text-decoration: none;
  }

  pre {
    margin:      0;
    white-space: pre-wrap;
    word-wrap:   break-word;

    a {
      color: $link-color;
      text-decoration: underline;
    }
  }

  .close-callout {
    color:      $text-color;
    cursor:     pointer;
    position:   absolute;
    top:        4px;
    right:      -1px;
    border:     none;
    background: transparent;
  }

  &.success {
    background-color: $success;
    color:            darken($success, 50%);
    border-color:     darken($success, 10%);
  }

  &.warning {
    background-color: $warning;
    color:            darken($warning, 60%);
    border-color:     darken($warning, 10%);
  }

  &.info {
    background-color: $info;
    color:            darken($info, 50%);;
    border-color:     darken($info, 10%);
  }

  &.alert {
    background-color: $alert;
    color:            darken($alert, 50%);
    border-color:     darken($alert, 10%);
  }
}
