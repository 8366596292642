/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../../../global/common";

.main-container {
  width: 70%;
}

.number-field-wrapper {
  width:         90px;
  margin-bottom: -20px;

  input {
    width:  75px;
    margin: 0 0 0 8px;
  }

  span {
    width: 300px;
  }
}

.cancel-after-inactivity-wrapper {
  display: flex;
}

