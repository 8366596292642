/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$page-item-padding:         30px;
$header-padding:            10px 50px 10px 30px;
$collapsible-header-height: 50px;

.collapse {
  background:    $element-bg;
  position:      relative;
  border:        1px solid $border-color;
  border-radius: $global-border-radius;
  margin-bottom: 10px;
  transition:    $transition;

  &[data-tag] {
    &::before {
      content: "";
      position: absolute;
      top: 0; bottom: 0; left: 0;
      width: 10px;
    }
  }

  &[data-tag="a"]::before {
    background-color: lighten($go-primary, 25%);
  }

  &[data-tag="b"]::before {
    background-color: lighten($go-secondary, 25%);
  }

  &.expanded {
    transition: $transition;
    box-shadow: 0 0 10px $box-shadow-color;
  }

  &.error {
    border-top: 1px solid $failed;
  }

  &.warning {
    border-top: 1px solid darken($warning, 30%);
  }
}

.collapse-header {
  @include icon-after($fa-var-angle-right);
  position:       relative;
  display:        flex;
  flex-wrap:      wrap;
  flex-direction: column;
  padding:        $header-padding;
  box-sizing:     border-box;
  cursor:         pointer;
  min-height:     $collapsible-header-height;
  @media (min-width: $screen-md) {
    justify-content: space-between;
  }
  @media (min-width: $screen-lg) {
    flex-direction: row;
    align-items:    center;
  }

  &:after {
    position:   absolute;
    right:      16px;
    font-size:  22px;
    color:      $icon-color;
    top:        50%;
    transition: $transition;
    transform:  translateY(-50%);
    margin:     0;
  }

  &.expanded {
    &:after {
      transform:  rotate(90deg) translateY(-50%);
      transition: $transition;
      right:      25px;
      top:        34%;
    }
  }
}

.non-collapse-header {
  position:       relative;
  display:        flex;
  flex-wrap:      wrap;
  flex-direction: column;
  padding:        $header-padding;
  box-sizing:     border-box;
  min-height:     $collapsible-header-height;
  @media (min-width: $screen-md) {
    justify-content: space-between;
  }
  @media (min-width: $screen-lg) {
    flex-direction: row;
    align-items:    center;
  }

  &:after {
    position:   absolute;
    right:      16px;
    font-size:  22px;
    color:      $icon-color;
    top:        50%;
    transition: $transition;
    transform:  translateY(-50%);
    margin:     0;
  }

  &.expanded {
    &:after {
      transform:  rotate(90deg) translateY(-50%);
      transition: $transition;
      right:      25px;
      top:        34%;
    }
  }
}

.header-details {
  display:        flex;
  flex-direction: column;
  float:          left;

  @media (min-width: $screen-md) {
    flex-direction: row;
    align-items:    center;
  }
  @media (min-width: $screen-lg) {
    width: auto;
  }
}

.actions {
  float:       right;
  display:     flex;
  margin:      20px 0;
  align-items: center;
  @media (min-width: $screen-xl) {
    width:  auto;
    margin: 0;
  }
  @media (min-width: $screen-lg) {
    width:  auto;
    margin: 0;
  }
}

.collapse-body {
  padding:    20px 30px;
  border-top: 1px solid $line-color;
}

.collapse-body.hide {
  display: none;
}
