/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../../global/common";
@import "fillable_section";
@import "../../components/buttons/index";
@import "../../components/buttons/wizard_buttons";

.actions {
  @extend .fillable;

  .save-btns {
    white-space: nowrap;
  }
}

.btn-secondary {
  &:disabled {
    &:hover {
      color: $go-primary;
    }
  }
}
